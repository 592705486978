<template>
	<div class="container">

    <div class="card card--purple card--notification">
      <div class="card-container">
        <div class="card-left">
          <ui-icon size="48">account_circle</ui-icon>
          <h3>My Swisscom</h3>
          <p>Swisscom Produkte und Kosten im Griff behalten</p>
        </div>
        <div class="card-right">
          <h2 class="font font--light">Swisscom Login</h2>
          <p>Melden Sie sich bitte mit Ihrem Benutzernamen oder Ihrer Mobilnummer an. <ui-icon size="12">account_circle</ui-icon></p>
          <ui-form nowrap type="|" item-margin-bottom="16" action-align="center">
            <template #default="{ actionClass }">
              <ui-form-field>
                <label>Username</label>
                <ui-textfield
                    id="input-1"
                    v-model="form.username"
                    type="text"
                    placeholder="Enter username"
                    required
                    outlined>
                </ui-textfield>
              </ui-form-field>

              <ui-form-field>
                <label>Password</label>
                <ui-textfield
                    id="input-2"
                    v-model="form.password"
                    placeholder="Enter password"
                    required
                    input-type="password"
                />
              </ui-form-field>
              <ui-form-field :class="actionClass">
                <ui-button raised type="submit" v-on:click="login">Senden</ui-button>
              </ui-form-field>
            </template>
          </ui-form>
        </div>
      </div>

    </div>

	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: "login-view",
	data() {
		return {
			form: {
				username: "",
				password: "",
			}
		};
	},
  computed: {...mapState(['user'])},
	methods: {
    async login(e) {
      e.preventDefault();
      const response = await fetch("/backend/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: this.form.username,
          password: this.form.password,
        }),
      });
      const { user, token} = await response.json();
      this.$store.dispatch('updateToken', token)
      this.$store.dispatch('updateUser', user)

      // update the logo for current user
      this.$store.dispatch('updateLogo', this.user.logo)

      // dispatching an action to update user login status
      this.$store.dispatch('updateUserLoginStatus', true)

      this.$router.push({ name: 'Dashboard' })
    },
	},
};
</script>

<style lang="scss" scoped>
    .container{
		height: 100vh;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		// padding: 50px;
    text-align: left;
    }

    .card {
      border-top: 5px solid #a63297;
    }

    .card-container {
      display: flex;
      flex-direction: row;
      width: 700px;
    }

    .card-left{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #a63297;
      flex: 1 1 0px;
      flex-direction: column;
      color: white;
    }

    .card-left p{
      width: 80%;
    }

    .card-right {
      flex: 1 1 0px;
      padding: 40px;
    }

</style>
