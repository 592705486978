import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

import BalmUI from 'balm-ui'; // Official Google Material Components
import 'balm-ui-css';

const app = createApp(App)
app.use(VueSidebarMenu)
app.use(router)
app.use(store)

app.use(BalmUI, {
    // Optional. Overwrite `<ui-button>` props with default value.
    UiButton: {
        // some props
    }
});

app.mount("#app")