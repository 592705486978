import { createStore } from 'vuex'
import createPersistedState from "vuex-plugin-persistedstate";

const store = createStore({
  state: {
    isLogged: 'false',
    logo: null,
    token: null,
    user: null
  },
  getters: {
    getUserLoginStatus: state => {
      return !!state.token;
    },
    getLogo: state => {
      return state.logo
    },
    getToken: state => {
      return state.token
    }
  },
  mutations: {
    updateUserLoginStatus: (state, payload) => {
      state.isLogged = payload
    },
    updateLogo: (state, payload) => {
      state.logo = payload
    },
    updateToken: (state, payload) => {
      state.token = payload
    },
    updateUser: (state, payload) => {
      state.user = payload
    }
  },
  actions: {
    updateUserLoginStatus: (context, payload) => {
      context.commit('updateUserLoginStatus', payload)
    },
    updateLogo: (context, payload) => {
      context.commit('updateLogo', payload)
    },
    updateToken: (context, payload) => {
      context.commit('updateToken', payload)
    },
    updateUser: (context, payload) => {
      context.commit('updateUser', payload)
    }
  },
  modules: {
  },
  plugins: [
    createPersistedState(),
  ]
})

export default store