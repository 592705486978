<template>
  <div>
    <nav>
      <div class="header">
        <img src="../assets/SC_navbar_logo.png" class="navbar-logo">
      </div>
      <div class="login">
        <ul>
            <ui-button>
              <router-link to="/login">Login Sandbox</router-link>
            </ui-button>
          <ui-button>
            <router-link to="/login">Contact Swisscom</router-link>
          </ui-button>
        </ul>
      </div>
    </nav>
    <div class="module mid">
      <h2>Swisscom API Sandbox</h2>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

      export default {
  name: 'home-view',
}
</script>

<style lang="scss" scoped>

nav {
  display: flex;
  justify-content: space-between;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  nav {
    flex-direction: column;
  }
}

.module {
  background: 
    linear-gradient(
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url("../assets/filter_header.jpeg");
  background-size: cover;
  position: relative;
  float: left;
  width: 100%;
  height: 400px;
}

.mid h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  color: white;
  text-transform: uppercase;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 2rem;
  transform: translate(-50%, -50%);
}

  .content{
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    gap: 50px;
  }

  a{
    color: white;
    text-decoration: none;
  }

  a:hover{
    color: white;
    text-decoration: none;
  }

  .header-image img{
    width: 100%;
  }

  .header-image {
    width: 100%;
  }

  button {
    margin-right: 10px;
  }

  .navbar-expand-lg {
    justify-content: flex-end;
  }

  button {
    background-color: white;
    border: none;
  }

  button a {
    color: #015;
    padding-bottom: 5px;
    border-bottom: 1px solid #015;
  }

  button:hover {
    background-color: white;
  }

  button:active {
    background-color: white;
  }

  button a:hover {
    color: #3380e5;
    padding-bottom: 5px;
    border-bottom: 1px solid #3380e5;
  }

  button a:active {
    color: #3380e5;
    padding-bottom: 5px;
    border-bottom: 1px solid #3380e5;
  }

  .navbar-logo {
    width: 20%;
  }

</style>
