<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
    metaInfo: {
    title: 'Banking API Sandbox',
    titleTemplate: '%s | vue-meta Example App'
  }
}
</script>

<style lang="scss">
#app {
  font-family: TheSans,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body h2,
body h3{
  font-weight: normal;
}

</style>
